    <template>
  <div>
    <b-modal
      v-model="modalCreateCharge"
      modal-class="modal-primary"
      title-class="h3 text-white"
      hide-footer
      title-tag="h3"
      size="md"
      centered
      :title="titleModal"
      @hide="closeModal()"
    >
      <b-container>
        <div>
          <ValidationObserver ref="modalCreateAndEditCommissions">
            <b-row class="mt-1 mb-1">
              <!-- title / fee / loss / profit-->
              <b-col lg="12" class="pr-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="titleChargeInput"
                  rules="required"
                >
                  <label class="h5">Title</label>
                  <b-input-group-prepend>
                    <b-form-input
                      id="titleChargeInput"
                      type="text"
                      v-model="titleInputCharge"
                      :class="{ 'is-border-danger': errors[0] }"
                    ></b-form-input>

                    <b-button
                      variant="gradient-primary"
                      class="text-center ml-1"
                      style="width: 35px; padding-left: 10px"
                      @click="saveTitleFeeLossCharge()"
                    >
                      <feather-icon icon="SaveIcon" />
                    </b-button>
                  </b-input-group-prepend>
                </ValidationProvider>
              </b-col>

              <b-col lg="4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="feeChargeInput"
                  :rules="{ required: true, min: 1, min_value: 1 }"
                >
                  <label class="h5 mt-2">Fee</label>
                  <b-input-group prepend="$" class="input-group-merge">
                    <b-form-input
                      id="feeChargeInput"
                      placeholder="00.00"
                      v-model.number="feeCharge"
                      type="number"
                      @input="feeLossChange()"
                      :class="{ 'is-border-danger': errors[0] }"
                    />
                  </b-input-group>
                  <!-- v-validate="{required:true, min:1}" -->
                </ValidationProvider>
              </b-col>

              <b-col lg="4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="lossChargeInput"
                  :rules="{ required: true, min: 0, min_value: 0 }"
                >
                  <label class="h5 mt-2">Loss</label>
                  <b-input-group prepend="$" class="input-group-merge">
                    <b-form-input
                      id="lossChargeInput"
                      placeholder="00.00"
                      v-model.number="lossCharge"
                      type="number"
                      @input="feeLossChange()"
                      :class="decimalFormatValidate(errors)"
                    />
                  </b-input-group>
                </ValidationProvider>
              </b-col>

              <b-col :lg="3">
                <label class="h5 mt-2">Profit</label>
                <b-input-group
                  prepend="$"
                  :class="[
                    'input-group-merge',
                    G_GET_SKIN == 'dark' ? 'disabled_icon' : '',
                  ]"
                >
                  <b-form-input
                    placeholder="00.00"
                    type="number"
                    v-model="profitCharge"
                    disabled
                  />
                </b-input-group>
              </b-col>

              <b-col lg="1" class="px-0">
                <label class="h5 mt-2" style="min-height: 38px"></label>
                <b-button
                  variant="gradient-primary"
                  class="text-center"
                  style="width: 35px; padding-left: 10px"
                  @click="saveTitleFeeLossCharge()"
                >
                  <feather-icon icon="SaveIcon" />
                </b-button>
              </b-col>

              <b-col lg="12">
                <label class="h5 mt-2 mb-1">Sales Made</label>
                <label class="mx-2">|</label>
                <label class="h5 mt-2 mb-1 m-left-commissions"
                  >Percentage</label
                >

                <ValidationProvider
                  name="commissions_items"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <div v-for="(itemx, index) in listComissions" :key="index">
                    <b-row class="mb-0">
                      <b-col lg="5">
                        <b-form-group>
                          <b-input-group>
                            <b-input-group-prepend>
                              <b-input-group-text
                                class="bg-primary text-white"
                                style="padding-right: 20px"
                              >
                                <span>Nº</span>
                              </b-input-group-text>
                            </b-input-group-prepend>
                            <b-form-spinbutton
                              v-model="itemx.sales_count"
                              min="1"
                              max="100"
                              :disabled="
                                listComissions.indexOf(itemx) == 0
                                  ? true
                                  : false
                              "
                              @input="
                                changeNumCommissions(itemx, $event, index)
                              "
                              :class="[
                                { 'is-border-danger': errors[0] },
                                alertCommissions[index] == true
                                  ? 'is-border-danger'
                                  : '',
                              ]"
                            />
                          </b-input-group>
                          <span
                            v-if="alertCommissions[index] == true"
                            class="w-100 text-right d-block"
                            style="font-size: 11px; padding-top: 5px"
                          >
                            Duplicated values!
                          </span>
                        </b-form-group>
                      </b-col>

                      <b-col lg="5" class="">
                        <b-form-group>
                          <b-input-group>
                            <b-input-group-prepend>
                              <b-input-group-text
                                class="bg-primary text-white"
                                style="padding-right: 20px"
                              >
                                <span>%</span>
                              </b-input-group-text>
                            </b-input-group-prepend>

                            <b-form-spinbutton
                              v-model="itemx.percentage_rate"
                              inline
                              step="0.1"
                            ></b-form-spinbutton>
                          </b-input-group>
                        </b-form-group>
                      </b-col>

                      <!--:::::INI ONE ROW:::::-->
                      <b-col
                        lg="2"
                        class="px-0 text-center"
                        v-if="listComissions.indexOf(itemx) == 0"
                      >
                        <b-button
                          variant="gradient-primary"
                          class="text-center"
                          style="width: 35px; padding-left: 10px"
                          @click="
                            itemx.commission_id
                              ? saveCommissionsById(itemx, (type = 'Edit'))
                              : saveCommissionsById(itemx, (type = 'New'))
                          "
                        >
                          <feather-icon icon="SaveIcon" />
                        </b-button>

                        <b-button
                          @click="addComissions()"
                          :disabled="listComissions.length >= 5 ? true : false"
                          variant="gradient-primary"
                          style="
                            width: 35px;
                            padding-left: 10px;
                            margin-left: 5px;
                          "
                        >
                          <feather-icon icon="PlusIcon" />
                        </b-button>
                      </b-col>
                      <!--:::::FIN ONE ROW:::::-->

                      <b-col lg="2" class="px-0 text-center" v-else>
                        <b-button
                          :variant="
                            itemx.commission_id
                              ? 'gradient-primary'
                              : 'gradient-success'
                          "
                          class="text-center"
                          style="width: 35px; padding-left: 10px"
                          @click="
                            itemx.commission_id
                              ? saveCommissionsById(itemx, (type = 'Edit'))
                              : saveCommissionsById(itemx, (type = 'New'))
                          "
                        >
                          <feather-icon icon="SaveIcon" />
                        </b-button>

                        <b-button
                          @click="deleteComissions(itemx)"
                          variant="gradient-danger"
                          class="text-center"
                          style="
                            width: 35px;
                            padding-left: 10px;
                            margin-left: 5px;
                          "
                        >
                          <feather-icon icon="Trash2Icon" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                </ValidationProvider>
              </b-col>
            </b-row>
          </ValidationObserver>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import ChargeService from "../service/chargeService";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    charge_id: {
      type: Number,
      default: () => ({}),
    },
  },
  data() {
    return {
      modalCreateCharge: false,
      titleModal: "EDIT CHARGE",

      titleInputCharge: "",
      listComissions: [{ percentage_rate: 3, sales_count: 1 }],
      feeCharge: 0,
      lossCharge: 0,
      profitCharge: 0,

      newArray: [],
      alertCommissions: [],
      validateStateCommissions: false,

      listNewCommissions: [],
    };
  },
  methods: {
    ...mapActions({
      updateChargeFilter: "BusinessSettings/A_UPDATE_CHARGE_FILTER",
    }),
    closeModal() {
      this.$emit("close");
    },
    //TITLE/FEE/LOSS/PROFIT::
    feeLossChange() {
      this.profitCharge = this.feeCharge - this.lossCharge;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async getCharge() {
      this.addPreloader();
      const res = await ChargeService.getChargeCommissionsFeeloss({
        subcharge_id: this.charge_id,
      });
      if (res.status == 200) {
        this.titleInputCharge = res.data[0].subcharge_name;
        this.feeCharge = res.data[0].fee;
        this.lossCharge = res.data[0].loss;
        this.profitCharge = this.feeCharge - this.lossCharge;
        this.listComissions = res.data[0].commissions.sort(
          (a, b) => a.sales_count - b.sales_count
        );
      }
      this.removePreloader();
    },
    async saveTitleFeeLossCharge() {
      const veeValidation =
        await this.$refs.modalCreateAndEditCommissions.validate();
      if (veeValidation && this.lossCharge * 1 <= this.feeCharge * 1) {
        this.addPreloader();
        try {
          const res = await ChargeService.updateSubchargeFeeLoss({
            subcharge_id: this.charge_id,
            new_name: this.titleInputCharge.toUpperCase(),
            newFee: this.feeCharge,
            newLoss: this.lossCharge,
            user_id: this.G_GET_CURRENT_USER.user_id,
          });
          this.removePreloader();
          await this.showSuccessSwal();
          this.closeModal();
        } catch (err) {
          throw err;
        }
        this.removePreloader();
      } else {
        this.showErrorSwal("Error trying to update the charge");
      }
    },
    decimalFormatValidate(errors) {
      let feeCharge = Math.round(parseFloat(this.feeCharge) * 100) / 100;
      let lossCharge = Math.round(parseFloat(this.lossCharge) * 100) / 100;
      return [
        { "is-border-danger": errors[0] },
        feeCharge >= lossCharge ? "" : "is-border-danger",
      ];
    },

    //COMMISSIONS::
    addComissions() {
      if (this.listComissions.length < 5) {
        if (this.listComissions.length != 0) {
          let endSalesCount =
            this.listComissions[this.listComissions.length - 1].sales_count;
          let endPercentage =
            this.listComissions[this.listComissions.length - 1].percentage_rate;

          const obj = {
            percentage_rate: endPercentage + 0.1,
            sales_count: 1 + endSalesCount,
          };
          this.listComissions.push(obj);

          this.validateStateCommissions = true;
        }
      }
    },
    async deleteComissions(itemz) {
      if (itemz.commission_id > 0) {
        const swalValidation = await this.showConfirmSwal();
        if (!swalValidation.isConfirmed) return;
        this.addPreloader();
        const res = await ChargeService.deleteSubchargeCommission({
          subcharge_id: this.charge_id,
          commission_id: itemz.commission_id,
          operation_type: 3,
          newSalesCount: itemz.sales_count,
          newPercentage: "" + itemz.percentage_rate + "",
          user_id: this.G_GET_CURRENT_USER.user_id,
        });
        this.removePreloader();
      }
      this.listComissions.splice(this.listComissions.indexOf(itemz), 1);
    },
    validateArray() {
      let listComissionsArray = this.listComissions.map(
        (item) => item.sales_count
      );
      if (listComissionsArray.length == new Set(listComissionsArray).size) {
        //validate true
        this.validateStateCommissions = true;
        this.listComissions.map((item, index) => {
          this.alertCommissions[index] = false;
        });
      }
    },
    async saveChargeCommissions() {
      this.validateArray();
      const veeValidation =
        await this.$refs.modalCreateAndEditCommissions.validate();

      if (
        veeValidation &&
        this.validateStateCommissions &&
        this.feeCharge > this.lossCharge
      ) {
        const swalValidation = await this.showConfirmSwal();
        if (swalValidation.isConfirmed) {
          this.addPreloader();
          const res = await ChargeService.createSubcharge({
            subcharge_name: this.titleInputCharge.toUpperCase(),
            id_entrance: this.G_GET_ENTRANCES_TYPE_ID,
            fee: this.feeCharge,
            loss: this.lossCharge,
            user_id: this.G_GET_CURRENT_USER.user_id,
            commissions_rate: this.listComissions,
            module_id: this.moduleId,
          });
          if (res) {
            this.removePreloader();
          } else {
            this.removePreloader();
          }
          this.modalCreateCharge = false;
          this.updateChargeFilter(true);
        }
      } else {
        console.error("validation error.");
      }
    },
    changeNumCommissions(itemx, event, index) {
      const position = this.listComissions.indexOf(itemx);
      this.newArray = [...this.listComissions];
      this.newArray.splice(position, 1); //clear array

      var searchInArray = this.newArray.find(
        (element) => element.sales_count == itemx.sales_count
      );
      if (searchInArray) {
        this.alertCommissions[index] = true;
        this.validateStateCommissions = false;
      } else {
        this.alertCommissions[index] = false;
        this.validateStateCommissions = true;
      }
      this.validateArray();
    },
    async saveCommissionsById(itemx, type) {
      this.validateArray(); //validate array commissions
      if (this.validateStateCommissions == false) {
        return false;
      }

      const swalValidation = await this.showConfirmSwal();
      if (!swalValidation.isConfirmed) return;
      this.addPreloader();
      try {
        const res = await ChargeService.updateSubchargeCommission({
          subcharge_id: this.charge_id,
          commission_id: type == "New" ? null : itemx.commission_id,
          operation_type: type == "New" ? 1 : 2,
          newSalesCount: itemx.sales_count,
          newPercentage: itemx.percentage_rate,
          user_id: this.G_GET_CURRENT_USER.user_id,
        });
        this.getCharge();
      } catch (err) {
        console.error("Error trying to update the charge");
        throw err;
      }
      this.removePreloader();
    },
  },
  created() {
    this.modalCreateCharge = true;
    this.getCharge();
  },
  computed: {
    ...mapGetters({
      G_GET_DATAGLOBAL: "BusinessSettings/G_DATAGLOBAL",
      G_GET_ENTRANCES_TYPE_ID: "BusinessSettings/G_ENTRANCES_TYPE_ID",
      G_GET_CURRENT_USER: "auth/currentUser",
      G_GET_CHARGE_FILTER: "BusinessSettings/G_UPDATE_CHARGE_FILTER",
      G_GET_SKIN: "appConfig/skin",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  watch: {},
};
</script>
<style>
.disabled_icon .input-group-prepend .input-group-text {
  border-color: #484848 !important;
}
</style>