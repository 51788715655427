<template>
  <b-modal
    v-model="control"
    id="modal-entrances-tracking"
    modal-class="modal-primary"
    title="Tracking"
    title-tag="h3"
    size="xmd"
    hide-footer
    @hidden="close"
    scrollable
  >
    <b-container>
      <b-row>
        <b-table
          responsive
          sticky-header="80vh"
          :items="items"
          :fields="visibleFields"
          :style="['fontSize:5px', 'fontWeight:thin']"
        >
          <template #cell(full_name)="data">
            <span>{{ data.item.full_name }}</span>
          </template>

          <template #cell(event_type)="data">
            <span
              class="text-capitalize"
              :class="[toggleColorStatus(data.item.event_type)]"
              >{{ data.item.event_type }}</span
            >
          </template>

          <template #cell(updated_at)="data">
            {{ data.item.updated_at | myGlobalWithHour }}
          </template>

          <template #cell(fee)="data">
            <span class="text-capitalize">$ {{ data.item.fee }}</span>
          </template>

          <template #cell(loss)="data">
            <span class="text-capitalize">$ {{ data.item.loss }}</span>
          </template>

          <template #cell(subcharge_name)="data">
            <span>{{ data.item.subcharge_name }}</span>
          </template>

          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
        </b-table>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import ChargeService from "../service/chargeService";
export default {
  props: {
    id: {
      required: true,
      type: Number,
    },
    type: {
      type: String,
      default: "charge",
    },
  },
  data() {
    return {
      control: false,
      fields: [
        {
          key: "full_name",
          label: "User",
          visible: true,
        },
        {
          key: "subcharge_name",
          label: "Charge Name",
          tdClass: "text-center",
          thClass: "text-center",
          visible: true,
        },
        {
          key: "fee",
          label: "Fee",
          tdClass: "text-center",
          thClass: "text-center",
          visible: true,
        },
        {
          key: "loss",
          label: "Loss",
          tdClass: "text-center",
          thClass: "text-center",
          visible: true,
        },
        {
          key: "sales_count",
          label: "Sales Count",
          tdClass: "text-center",
          thClass: "text-center",
          visible: false,
        },
        {
          key: "percentage",
          label: "Commision",
          tdClass: "text-center",
          thClass: "text-center",
          visible: false,
        },
        {
          key: "event_type",
          label: "Action",
          visible: true,
        },
        {
          key: "updated_at",
          label: "Date",
          visible: true,
        },
      ],
      items: [],
    };
  },
  computed: {
    visibleFields() {
      if (this.type === "charge") {
        return this.fields.filter((field) => field.visible);
      } else {
        return this.fields;
      }
    },
  },
  methods: {
    toggleColorStatus(value) {
      switch (value) {
        case "created":
          return "text-success";
          break;
        case "edited":
          return "text-primary";
          break;
        case "deleted":
          return "text-danger";
          break;
      }
    },
    close() {
      this.$emit("close");
      this.control = false;
    },
  },
  async created() {
    this.control = true;
    this.addPreloader();
    let data = [];
    if (this.type === "charge") {
      const { data } = await ChargeService.getChargeTrackingEntries({
        charge_id: this.id,
      });
      this.items = data;
    } else {
      const { data } = await ChargeService.getChargeTrackingCommisions({
        charge_id: this.id,
      });
      this.items = data;
    }

    this.removePreloader();
  },
};
</script>
<style>
.observation_table {
  width: 85px;
  white-space: initial;
}
</style>