<template>
  <div class="px-2">
    <b-tabs
      active-tab-class="p-0 "
      pills
      nav-class="mb-0 mt-1"
      active-nav-item-class="bg-info box-shadow-info border-info info"
    >
      <b-tab
        lazy
        title="Active"
        :title-link-class="[bgTabsNavs, 'sub-tab px-3']"
        @click="getByChargeStatus(0)"
      >
      </b-tab>
      <b-tab
        lazy
        title="Inactive"
        :title-link-class="[bgTabsNavs, 'sub-tab px-3']"
        @click="getByChargeStatus(1)"
      >
      </b-tab>
    </b-tabs>

    <!--<b-nav card-header pills class="m-0">
          <b-nav-item
            v-for="(item, index) in tabsChildren"
            :key="index"
            :to="{ name: item.route }"
            exact
            exact-active-class="active"
            :link-classes="['px-3', bgTabsNavs]"
          >
            {{ item.title }}
          </b-nav-item>
      </b-nav>-->

    <!--<pre>
      {{ isDeleted }}
    </pre> -->

    <filter-slot
      :filter="filters"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :total-rows="totalRows"
      :filter-principal="filterPrincipal"
      @reload="$refs['entrancesChargeListTable'].refresh()"
    >
      <template #table>
        <b-table
          small
          slot="table"
          no-provider-filtering
          ref="entrancesChargeListTable"
          :items="myProvider"
          :fields="fields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
        >
          <template v-slot:cell(fee)="data"> $ {{ data.item.fee }} </template>

          <template v-slot:cell(loss)="data"> $ {{ data.item.loss }} </template>

          <template v-slot:cell(profit)="data">
            $ {{ data.item.profit }}
          </template>

          <template v-slot:cell(commission)="data">
            $ {{ data.item.commission }}
          </template>

          <template v-slot:cell(created_at)="data">
            {{ data.item.created_at | myGlobalWithHour }}
          </template>

          <template v-slot:cell(tracking)="data">
            <feather-icon
              icon="ListIcon"
              title="INFO"
              size="20"
              class="cursor-pointer text-primary"
              @click="openTrackingModal(data.item.id, 'charge')"
            />
          </template>

          <template v-slot:cell(tracking_com)="data">
            <feather-icon
              icon="ListIcon"
              title="INFO"
              size="20"
              class="cursor-pointer text-warning"
              @click="openTrackingModal(data.item.id, 'commision')"
            />
          </template>

          <template v-slot:cell(action)="data">
            <feather-icon
              v-if="statusActive == 0"
              icon="EditIcon"
              title="EDIT"
              size="20"
              class="cursor-pointer text-primary"
              @click="openEditModalCharge(data.item.id)"
            />
            <feather-icon
              v-if="statusActive == 0"
              icon="TrashIcon"
              title="DELETE"
              size="20"
              class="cursor-pointer text-danger ml-1"
              @click="deleteCharge(data.item.id)"
            />
          </template>

          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <modal-charge-tracking
      v-if="trackingModalOpened"
      :type="type"
      :id="charge_id"
      @close="closeTrackingModal"
    />
    <modal-edit-charge
      v-if="openModalChargeEdit"
      :charge_id="currentCharge"
      no-close-on-esc="false"
      o-close-on-backdrop="false"
      @reload="updateChargeTable"
      @close="closeChargeEditModal"
    />
  </div>
</template>

<script>
import Fields from "../data/fields.settingsCharge";
import Filters from "../data/filters.settingsCharge";
import ChargeService from "../service/chargeService";
import { mapGetters, mapActions } from "vuex";
import ModalEditCharge from "./ModalEditCharge.vue";
import ModalChargeTracking from "./ModalChargeTracking.vue";

export default {
  components: {
    ModalChargeTracking,
    ModalEditCharge,
  },
  props: {
    isDeleted: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      currentCharge: {},
      editOperation: 2,
      charge_id: null,
      fields: Fields,
      filters: Filters,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Entrance...",
        model: "",
      },
      openModalChargeEdit: false,
      paginate: {
        currentPage: 1,
        perPage: 100,
      },
      startPage: null,
      trackingModalOpened: false,
      totalRows: 0,
      toPage: null,
      paymentStatus: null,
      
      statusActive: false,
      type: "charge",
    };
  },
  methods: {
    updateChargeTable() {
      this.$refs["entrancesChargeListTable"].refresh();
    },
    async myProvider(ctx) {
      try {
        const params = {
          isDeleted: this.statusActive,
          payment_status: this.paymentStatus,
          description: this.filterPrincipal.model,
          from: this.filters[0].model,
          to: this.filters[1].model,
          page: this.paginate.currentPage,
          perpage: this.paginate.perPage,
          subcharge_id: null,
          payment_status: null,
        };

        const { data } = await ChargeService.getAllCharge(params);
        this.totalRows = data.total;
        this.startPage = data.from;
        this.toPage = data.to;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        return data.data;
      } catch (err) {
        console.error("Error trying to get all the entrances");
        throw err;
      }
    },
    ...mapActions({
      updateEntrancesTypeId: "BusinessSettings/A_UPDATE_ENTRANCES_TYPE_ID",
      updateChargeId: "BusinessSettings/A_UPDATE_ENTRANCES_CHARGE_ID",
    }),
    openEditModalCharge(charge_id) {
      this.currentCharge = charge_id;
      this.openModalChargeEdit = true;
      this.updateChargeId(charge_id);
    },
    closeChargeEditModal() {
      this.openModalChargeEdit = false;
      this.updateChargeTable();
    },
    openTrackingModal(id, type) {
      this.charge_id = id;
      this.type = type;
      this.trackingModalOpened = true;
    },
    closeTrackingModal() {
      this.trackingModalOpened = false;
      this.charge_id = null;
    },
    async deleteCharge(id) {
      const swalValidation = await this.showConfirmSwal();
      if (!swalValidation.isConfirmed) return;
      try {
        await ChargeService.deleteChargeEntrance({
          user_id: this.currentUser.user_id,
          charge_id: id,
        });
        this.updateChargeTable();
      } catch (err) {
        console.error("Error trying to delete the charge");
        throw err;
      }
    },
    async getAllSubCharge() {
        this.showHideColumnTable(false);
    },
    getByChargeStatus(status){
        this.statusActive = status;
        this.updateChargeTable();
        this.showHideColumnTable(status);
    },
    showHideColumnTable(hideColumn){
        if(hideColumn){
          this.fields[10].thClass = 'text-center d-none';
          this.fields[10].tdClass = 'text-center d-none';
        }else{
          this.fields[10].thClass = 'text-center';
          this.fields[10].tdClass = 'text-center';
        }
    },
  },
  created() {
      this.getAllSubCharge();
      this.updateEntrancesTypeId(3);
  },
  computed: {
    ...mapGetters({
      G_ENTRANCES_TYPE_ID: "BusinessSettings/G_ENTRANCES_TYPE_ID",
      G_UPDATE_CHARGE_TABLE: "BusinessSettings/G_UPDATE_CHARGE_TABLE",
      currentUser: "auth/currentUser",
      G_GET_CHARGE_FILTER: "BusinessSettings/G_UPDATE_CHARGE_FILTER",
    }),
  },
  watch: {
    G_UPDATE_CHARGE_TABLE(currentState) {
      if (currentState) {
        this.updateChargeTable();
      }
    },
    G_GET_CHARGE_FILTER(currentState) {
      if (currentState) {
        this.getAllSubCharge();
      }
    },
  },
};
</script>
<style>
.tdClass {
  padding: 15px 5px 15px 5px !important;
}
#dropdown-1 button {
  min-width: 120px;
  padding: 5px 15px 5px 5px !important;
  color: #eee;
  background-color: #eee;
  border: solid 0px;
}
</style>
