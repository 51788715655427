import { amgApi } from "@/service/axios";

class ChargeService {
  async getAllCharge(body) {
    const data = await amgApi.post(
      "/business/settings/get-addcharge-entrances",
      body
    );
    return data;
  }

  async getChargeTrackingEntries(body) {
    const data = await amgApi.post(
      "/business/settings/get-tracking-by-charge-entrance",
      body
    );
    return data;
  }

  async getChargeTrackingCommisions(body) {
    const data = await amgApi.post(
      "/business/settings/get-tracking-by-charge-commision",
      body
    );
    return data;
  }

  async deleteChargeEntrance(body) {
    const data = await amgApi.post(
      "/business/settings/delete-charge-entrance",
      body
    );
    return data;
  }

  async createSubcharge(body) {
    const data = await amgApi.post(
      "/business/settings/create-addcharge-subcharge",
      body
    );
    return data;
  }

  async getAllSubCharges(body) {
    const { data } = await amgApi.post(
      "/business/settings/get-subcharges-list",
      body
    );
    return data;
  }

  async getChargeCommissionsFeeloss(body) {
    const data = await amgApi.post(
      "/business/settings/get-subcharge-commissions-feeloss",
      body
    );
    return data;
  }

  async updateSubchargeCommission(body) {
    if (Array.isArray(body)) {
      let fu = [];
      body.forEach((item) => {
        let bar = amgApi.post(
          "/business/settings/update-subcharge-commission",
          item
        );
        fu.push(bar);
      });
      try {
        let res = await Promise.all(fu);
        return true;
      } catch (error) {
        return false;
      }
    } else {
      const data = amgApi.post(
        "/business/settings/update-subcharge-commission",
        body
      );
      return data;
    }
  }

  async deleteSubchargeCommission(body) {
    const data = await amgApi.post(
      "/business/settings/update-subcharge-commission",
      body
    );
    return data;
  }

  async createAddChargeEntrance(body) {
    const data = await amgApi.post(
      "/business/settings/create-addcharge-entrance",
      body
    );
    return data;
  }

  async changePaymentStatus(body) {
    const data = await amgApi.post(
      "/business/settings/update-addcharge-status",
      body
    );
    return data;
  }

  async editAddChargeEntrance(body) {
    const data = await amgApi.post(
      "/business/settings/update-addcharge-subcharge",
      body
    );
    return data;
  }
  async updateSubchargeFeeLoss(body) {
    const data = await amgApi.post(
      "/business/settings/update-subcharge-feeloss",
      body
    );
    return data;
  }

  async deleteSubCharge(body) {
    const data = await amgApi.post("/business/settings/delete-subcharge", body);
    return data;
  }

  async getDeletedSubcharge(body) {
    const data = await amgApi.post(
      "/business/settings/get-deleted-subcharge",
      body
    );
    return data;
  }
}

export default new ChargeService();
