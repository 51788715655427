export default [
  /*{
        label: "ID",
        key: "id",
        visible: true,
        tdClass: 'tdClass'
    },*/
  {
    label: "TYPE",
    key: "type",
    visible: true,
    tdClass: "tdClass",
  },
  {
    label: "CHARGE",
    key: "description",
    visible: true,
    tdClass: "tdClass",
  },

  {
    label: "DESCRIPTION",
    key: "description",
    visible: true,
    tdClass: "tdClass",
  },
  {
    label: "FEE",
    key: "fee",
    visible: true,
    thClass: "text-center",
    tdClass: "tdClass text-center",
  },
  {
    label: "LOSS",
    key: "loss",
    visible: true,
    thClass: "text-center",
    tdClass: "tdClass text-center",
  },
  {
    label: "PROFIT",
    key: "profit",
    visible: true,
    thClass: "text-center",
    tdClass: "tdClass text-center",
  },
  {
    label: "CREATED BY",
    key: "created_by",
    visible: true,
    thClass: "text-center",
    tdClass: "tdClass text-center",
  },
  {
    label: "CREATED AT",
    key: "created_at",
    visible: true,
    tdClass: "tdClass",
  },
  {
    label: "TL-CHARGE",
    key: "tracking",
    visible: true,
    thClass: "text-center",
    tdClass: "tdClass text-center",
  },
  {
    label: "TL-COMMISION",
    key: "tracking_com",
    visible: true,
    thClass: "text-center",
    tdClass: "tdClass text-center",
  },
  {
    label: "ACTIONS",
    key: "action",
    visible: true,
    tdClass: "tdClass text-center",
    thClass: "text-center",
  },
];
