var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"modal-class":"modal-primary","title-class":"h3 text-white","hide-footer":"","title-tag":"h3","size":"md","centered":"","title":_vm.titleModal},on:{"hide":function($event){return _vm.closeModal()}},model:{value:(_vm.modalCreateCharge),callback:function ($$v) {_vm.modalCreateCharge=$$v},expression:"modalCreateCharge"}},[_c('b-container',[_c('div',[_c('ValidationObserver',{ref:"modalCreateAndEditCommissions"},[_c('b-row',{staticClass:"mt-1 mb-1"},[_c('b-col',{staticClass:"pr-0",attrs:{"lg":"12"}},[_c('ValidationProvider',{attrs:{"name":"titleChargeInput","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"h5"},[_vm._v("Title")]),_c('b-input-group-prepend',[_c('b-form-input',{class:{ 'is-border-danger': errors[0] },attrs:{"id":"titleChargeInput","type":"text"},model:{value:(_vm.titleInputCharge),callback:function ($$v) {_vm.titleInputCharge=$$v},expression:"titleInputCharge"}}),_c('b-button',{staticClass:"text-center ml-1",staticStyle:{"width":"35px","padding-left":"10px"},attrs:{"variant":"gradient-primary"},on:{"click":function($event){return _vm.saveTitleFeeLossCharge()}}},[_c('feather-icon',{attrs:{"icon":"SaveIcon"}})],1)],1)]}}])})],1),_c('b-col',{attrs:{"lg":"4"}},[_c('ValidationProvider',{attrs:{"name":"feeChargeInput","rules":{ required: true, min: 1, min_value: 1 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"h5 mt-2"},[_vm._v("Fee")]),_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"$"}},[_c('b-form-input',{class:{ 'is-border-danger': errors[0] },attrs:{"id":"feeChargeInput","placeholder":"00.00","type":"number"},on:{"input":function($event){return _vm.feeLossChange()}},model:{value:(_vm.feeCharge),callback:function ($$v) {_vm.feeCharge=_vm._n($$v)},expression:"feeCharge"}})],1)]}}])})],1),_c('b-col',{attrs:{"lg":"4"}},[_c('ValidationProvider',{attrs:{"name":"lossChargeInput","rules":{ required: true, min: 0, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"h5 mt-2"},[_vm._v("Loss")]),_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"$"}},[_c('b-form-input',{class:_vm.decimalFormatValidate(errors),attrs:{"id":"lossChargeInput","placeholder":"00.00","type":"number"},on:{"input":function($event){return _vm.feeLossChange()}},model:{value:(_vm.lossCharge),callback:function ($$v) {_vm.lossCharge=_vm._n($$v)},expression:"lossCharge"}})],1)]}}])})],1),_c('b-col',{attrs:{"lg":3}},[_c('label',{staticClass:"h5 mt-2"},[_vm._v("Profit")]),_c('b-input-group',{class:[
                  'input-group-merge',
                  _vm.G_GET_SKIN == 'dark' ? 'disabled_icon' : '' ],attrs:{"prepend":"$"}},[_c('b-form-input',{attrs:{"placeholder":"00.00","type":"number","disabled":""},model:{value:(_vm.profitCharge),callback:function ($$v) {_vm.profitCharge=$$v},expression:"profitCharge"}})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"1"}},[_c('label',{staticClass:"h5 mt-2",staticStyle:{"min-height":"38px"}}),_c('b-button',{staticClass:"text-center",staticStyle:{"width":"35px","padding-left":"10px"},attrs:{"variant":"gradient-primary"},on:{"click":function($event){return _vm.saveTitleFeeLossCharge()}}},[_c('feather-icon',{attrs:{"icon":"SaveIcon"}})],1)],1),_c('b-col',{attrs:{"lg":"12"}},[_c('label',{staticClass:"h5 mt-2 mb-1"},[_vm._v("Sales Made")]),_c('label',{staticClass:"mx-2"},[_vm._v("|")]),_c('label',{staticClass:"h5 mt-2 mb-1 m-left-commissions"},[_vm._v("Percentage")]),_c('ValidationProvider',{attrs:{"name":"commissions_items","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return _vm._l((_vm.listComissions),function(itemx,index){return _c('div',{key:index},[_c('b-row',{staticClass:"mb-0"},[_c('b-col',{attrs:{"lg":"5"}},[_c('b-form-group',[_c('b-input-group',[_c('b-input-group-prepend',[_c('b-input-group-text',{staticClass:"bg-primary text-white",staticStyle:{"padding-right":"20px"}},[_c('span',[_vm._v("Nº")])])],1),_c('b-form-spinbutton',{class:[
                              { 'is-border-danger': errors[0] },
                              _vm.alertCommissions[index] == true
                                ? 'is-border-danger'
                                : '' ],attrs:{"min":"1","max":"100","disabled":_vm.listComissions.indexOf(itemx) == 0
                                ? true
                                : false},on:{"input":function($event){return _vm.changeNumCommissions(itemx, $event, index)}},model:{value:(itemx.sales_count),callback:function ($$v) {_vm.$set(itemx, "sales_count", $$v)},expression:"itemx.sales_count"}})],1),(_vm.alertCommissions[index] == true)?_c('span',{staticClass:"w-100 text-right d-block",staticStyle:{"font-size":"11px","padding-top":"5px"}},[_vm._v(" Duplicated values! ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"lg":"5"}},[_c('b-form-group',[_c('b-input-group',[_c('b-input-group-prepend',[_c('b-input-group-text',{staticClass:"bg-primary text-white",staticStyle:{"padding-right":"20px"}},[_c('span',[_vm._v("%")])])],1),_c('b-form-spinbutton',{attrs:{"inline":"","step":"0.1"},model:{value:(itemx.percentage_rate),callback:function ($$v) {_vm.$set(itemx, "percentage_rate", $$v)},expression:"itemx.percentage_rate"}})],1)],1)],1),(_vm.listComissions.indexOf(itemx) == 0)?_c('b-col',{staticClass:"px-0 text-center",attrs:{"lg":"2"}},[_c('b-button',{staticClass:"text-center",staticStyle:{"width":"35px","padding-left":"10px"},attrs:{"variant":"gradient-primary"},on:{"click":function($event){itemx.commission_id
                            ? _vm.saveCommissionsById(itemx, (_vm.type = 'Edit'))
                            : _vm.saveCommissionsById(itemx, (_vm.type = 'New'))}}},[_c('feather-icon',{attrs:{"icon":"SaveIcon"}})],1),_c('b-button',{staticStyle:{"width":"35px","padding-left":"10px","margin-left":"5px"},attrs:{"disabled":_vm.listComissions.length >= 5 ? true : false,"variant":"gradient-primary"},on:{"click":function($event){return _vm.addComissions()}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1):_c('b-col',{staticClass:"px-0 text-center",attrs:{"lg":"2"}},[_c('b-button',{staticClass:"text-center",staticStyle:{"width":"35px","padding-left":"10px"},attrs:{"variant":itemx.commission_id
                            ? 'gradient-primary'
                            : 'gradient-success'},on:{"click":function($event){itemx.commission_id
                            ? _vm.saveCommissionsById(itemx, (_vm.type = 'Edit'))
                            : _vm.saveCommissionsById(itemx, (_vm.type = 'New'))}}},[_c('feather-icon',{attrs:{"icon":"SaveIcon"}})],1),_c('b-button',{staticClass:"text-center",staticStyle:{"width":"35px","padding-left":"10px","margin-left":"5px"},attrs:{"variant":"gradient-danger"},on:{"click":function($event){return _vm.deleteComissions(itemx)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}})],1)],1)],1)],1)})}}])})],1)],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }